import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { useCampaignPage } from 'context/CampaignPage';
import Pill from 'components/common/Pill';
import CampaignPageActivityFeed from './CampaignPageActivityFeed';

const GET_FUNDRAISER = gql`
  query GetFundraiser($id: String!) {
    findFundraisers(id: $id) {
      id
      donationCount
    }
  }
`;

const GET_TEAM = gql`
  query GetTeam($id: String!) {
    findTeams(id: $id) {
      id
      donationCount
    }
  }
`;

const CampaignPageFundraiserContributions = ({ className }) => {
  const { fundraiserId, teamId, pageType } = useCampaignPage();
  const isTeam = pageType === 'team';

  const { data } = useQuery(isTeam ? GET_TEAM : GET_FUNDRAISER, {
    variables: { id: isTeam ? teamId : fundraiserId },
  });

  const fundraiser = useMemo(
    () => data?.[isTeam ? 'findTeams' : 'findFundraisers'][0],
    [isTeam, data]
  );

  if (!fundraiser) return null;

  return (
    <div className={className}>
      <h2 className="text-xl font-medium mb-4">
        Contributions <Pill className="ml-1">{fundraiser.donationCount}</Pill>
      </h2>
      <CampaignPageActivityFeed
        className="mt-8 -mx-6"
        filter={{ [isTeam ? 'teamId' : 'fundraiserId']: fundraiser.id }}
        showFundraiserName={isTeam}
        allowLoadMore
        allowReply
      />
    </div>
  );
};

CampaignPageFundraiserContributions.propTypes = {
  className: PropTypes.string,
};

CampaignPageFundraiserContributions.defaultProps = {
  className: '',
};

export default CampaignPageFundraiserContributions;
