import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { parseNumber, formatSlateAsPlainText } from 'lib/formatters';
import { useCampaignPage } from 'context/CampaignPage';
import useBreakpoint from 'hooks/useBreakpoint';
import FundraisingUpdate, {
  FUNDRAISING_UPDATE_FIELDS,
  FUNDRAISING_UPDATE_CAMPAIGN_FIELDS,
  FUNDRAISING_UPDATE_FUNDRAISER_FIELDS,
} from 'components/common/FundraisingUpdate';
import Tiles from 'components/common/Tiles';
import Button from 'components/common/Button';
import PopMenu from 'components/common/PopMenu';
import ContextMenu from 'components/common/ContextMenu';
import ContextMenuLink from 'components/common/ContextMenuLink';

const GET_CAMPAIGN = gql`
  ${FUNDRAISING_UPDATE_FIELDS}
  ${FUNDRAISING_UPDATE_CAMPAIGN_FIELDS}
  ${FUNDRAISING_UPDATE_FUNDRAISER_FIELDS}

  query GetCampaignPageUpdates(
    $campaignId: String!
    $fundraiserWhere: SequelizeJSON
    $isFundraiser: Boolean!
    $teamWhere: SequelizeJSON
    $isTeam: Boolean!
  ) {
    findCampaigns(id: $campaignId) {
      id
      myPermissions
      ...FundraisingUpdateCampaignFields

      fundraisers(where: $fundraiserWhere) @include(if: $isFundraiser) {
        id
        updates(order: "reverse:createdAt") {
          ...FundraisingUpdateFields
          fundraiser {
            ...FundraisingUpdateFundraiserFields
          }
        }
      }

      teams(where: $teamWhere) @include(if: $isTeam) {
        id
        recentUpdates: updates(order: "reverse:createdAt") {
          ...FundraisingUpdateFields
          fundraiser {
            ...FundraisingUpdateFundraiserFields
          }
        }
        noteworthyUpdates: updates(
          order: "reverse:createdAt"
          where: {
            or: [{ embeddedMedia: { ne: null } }, { message: { ne: null } }, { source: "strava" }]
          }
        ) {
          ...FundraisingUpdateFields

          fundraiser {
            ...FundraisingUpdateFundraiserFields
          }
        }
      }
    }
  }
`;

const CampaignPageUpdates = ({ className }) => {
  const mobile = !useBreakpoint('md');
  const stravaEmbedWidth = mobile ? 'sm' : 'xl';

  const { campaignId, fundraiserId, teamId, pageType } = useCampaignPage();
  const isTeam = pageType === 'team';

  const { data } = useQuery(GET_CAMPAIGN, {
    variables: {
      campaignId,
      fundraiserWhere: { id: fundraiserId },
      isFundraiser: pageType === 'fundraiser',
      teamWhere: { id: teamId },
      isTeam,
    },
  });

  const [limit, setLimit] = useState(isTeam ? 3 : 5);
  const [view, setView] = useState('noteworthy');

  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const fundraiser = useMemo(() => campaign?.fundraisers?.[0], [campaign]);
  const team = useMemo(() => campaign?.teams?.[0], [campaign]);

  const hasPermissions = campaign?.myPermissions?.includes('manageCampaign');
  const emptyNoteworthy = team?.noteworthyUpdates?.length === 0;

  // Filter out any updates that will be completely empty
  const filteredUpdates = useMemo(() => {
    let updates;
    if (isTeam) {
      updates =
        view === 'recent' || emptyNoteworthy ? team?.recentUpdates : team?.noteworthyUpdates;
    } else {
      updates = fundraiser?.updates;
    }

    return (updates ?? []).filter((update) => {
      if (parseNumber(update.performanceUnits)) return true;
      if (update.embeddedMedia) return true;

      // If there's message, filter out the update if the message is just a bunch of spaces
      return !update.message || formatSlateAsPlainText(update.message).trim() !== '';
    });
  }, [isTeam, view, fundraiser, team, emptyNoteworthy]);

  // Client-side pagination
  const pagedUpdates = useMemo(() => filteredUpdates.slice(0, limit), [filteredUpdates, limit]);
  const showMore = filteredUpdates.length > pagedUpdates.length;

  if (pagedUpdates.length === 0) return null;

  return (
    <div className={className}>
      {isTeam &&
        (emptyNoteworthy ? (
          <h2 className={cx('text-lg font-medium mb-2', { 'ml-6': mobile })}>Recent updates</h2>
        ) : (
          <PopMenu
            className="px-4 py-2 -mx-4 hover:text-gray-600 transition-colors duration-200"
            title="View fundraising updates"
            trigger={
              <h2 className={cx('text-lg font-medium', { 'ml-6': mobile })}>
                {view === 'noteworthy' ? 'Noteworthy' : 'Recent'} updates
                <FontAwesomeIcon icon={faChevronDown} size="xs" className="ml-3" />
              </h2>
            }
            closeOnClick
          >
            <ContextMenu>
              <ContextMenuLink
                as="button"
                type="button"
                onClick={() => setView('noteworthy')}
                label="Noteworthy updates"
                isActive={view === 'noteworthy'}
                theme="bordered"
              />
              <ContextMenuLink
                as="button"
                type="button"
                onClick={() => setView('recent')}
                label="Recent updates"
                isActive={view === 'recent'}
                theme="bordered"
              />
            </ContextMenu>
          </PopMenu>
        ))}
      <Tiles columns={1} spacing="sm">
        {pagedUpdates.map((update) => (
          <FundraisingUpdate
            key={update.id}
            update={update}
            fundraiser={update.fundraiser}
            campaign={campaign}
            size={mobile ? 'sm' : 'lg'}
            showViewPage={isTeam}
            canFeature={hasPermissions}
            rounded={!mobile}
            stravaEmbedWidth={stravaEmbedWidth}
          />
        ))}
      </Tiles>
      {showMore && (
        <div className="text-center mt-8">
          <Button
            as="button"
            type="button"
            color="gray-800"
            onClick={() => setLimit(limit + (isTeam ? 6 : 5))}
            outline
          >
            Show More
          </Button>
        </div>
      )}
    </div>
  );
};

CampaignPageUpdates.propTypes = {
  className: PropTypes.string,
};

CampaignPageUpdates.defaultProps = {
  className: '',
};

export default CampaignPageUpdates;
