import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Image } from 'cloudinary-react';
import cx from 'classnames';

import config from 'config';
import { useCampaignPage } from 'context/CampaignPage';

const GET_CAMPAIGN = gql`
  query GetCampaignPageFundraiserHero(
    $campaignId: String!
    $fundraiserWhere: SequelizeJSON
    $teamWhere: SequelizeJSON
    $isFundraiser: Boolean!
    $isTeam: Boolean!
  ) {
    findCampaigns(id: $campaignId) {
      id
      campaignHeaderBackgroundImage
      fundraiserDefaultHeaderBackgroundImage
      fundraisers(where: $fundraiserWhere) @include(if: $isFundraiser) {
        id
        headerImage
      }
      teamDefaultHeaderBackgroundImage
      teams(where: $teamWhere) @include(if: $isTeam) {
        id
        headerImage
      }
    }
  }
`;

const CampaignPageFundraiserHero = ({ className }) => {
  const { campaignId, fundraiserId, teamId, pageType } = useCampaignPage();

  const { data } = useQuery(GET_CAMPAIGN, {
    variables: {
      campaignId,
      fundraiserWhere: { id: fundraiserId },
      teamWhere: { id: teamId },
      isFundraiser: pageType === 'fundraiser',
      isTeam: pageType === 'team',
    },
  });

  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const fundraiser = useMemo(() => campaign?.fundraisers?.[0], [campaign]);
  const team = useMemo(() => campaign?.teams?.[0], [campaign]);

  const img = useMemo(() => {
    if (pageType === 'fundraiser') {
      return (
        fundraiser?.headerImage ||
        campaign?.fundraiserDefaultHeaderBackgroundImage ||
        campaign?.campaignHeaderBackgroundImage ||
        config('/defaultFundraiserHeaderImage')
      );
    }

    return (
      team?.headerImage ||
      campaign?.teamDefaultHeaderBackgroundImage ||
      campaign?.campaignHeaderBackgroundImage ||
      config('/defaultTeamHeaderImage')
    );
  }, [pageType, campaign, fundraiser, team]);

  if (!campaign) return null;

  return (
    <Image
      publicId={img}
      alt=""
      width={1400}
      aspectRatio={img ? '4:3' : undefined}
      crop="fill"
      fetchFormat={img ? 'auto' : 'svg'}
      gravity={img ? 'auto:faces' : undefined}
      className={cx('block w-full', className)}
    />
  );
};

CampaignPageFundraiserHero.propTypes = {
  className: PropTypes.string,
};

CampaignPageFundraiserHero.defaultProps = {
  className: '',
};

export default CampaignPageFundraiserHero;
