import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

const CampaignPageFundraiserStatLine = ({ icon: Icon, label, as: Component, className }) => (
  <Component
    className={cx('flex items-center text-gray-700 font-medium leading-snug gap-x-4', className)}
  >
    <span className="w-8 text-center shrink-0">
      {Icon.iconName ? (
        <FontAwesomeIcon icon={Icon} className="text-2xl" />
      ) : (
        <Icon className="h-6" />
      )}
    </span>
    <p className="text-left">{label}</p>
  </Component>
);

CampaignPageFundraiserStatLine.propTypes = {
  icon: PropTypes.oneOf([
    PropTypes.node,
    PropTypes.shape({ iconName: PropTypes.string.isRequired }),
  ]).isRequired,
  label: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({ render: PropTypes.func }),
  ]),
  className: PropTypes.string,
};

CampaignPageFundraiserStatLine.defaultProps = {
  as: 'div',
  className: '',
};

export default CampaignPageFundraiserStatLine;
