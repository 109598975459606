import { ComponentPropsWithoutRef } from 'react';

import { useAppBar } from './AppBar';
import NavbarIconButton from './NavbarIconButton';

type AppBarIconButtonProps = {
  // This ensure TS doesn't lose track of the required props through the Omit<>
  icon: ComponentPropsWithoutRef<typeof NavbarIconButton>['icon'];
} & Omit<ComponentPropsWithoutRef<typeof NavbarIconButton>, 'theme'>;

const AppBarIconButton = (props: AppBarIconButtonProps) => {
  const { theme } = useAppBar();
  return <NavbarIconButton theme={theme} {...props} />;
};

export default AppBarIconButton;
