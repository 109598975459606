import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Image } from 'cloudinary-react';
import cx from 'classnames';

import config from 'config';
import { useCampaignPage } from 'context/CampaignPage';

const GET_CAMPAIGN = gql`
  query GetCampaignPageFundraiserAvatar(
    $campaignId: String!
    $fundraiserWhere: SequelizeJSON
    $teamWhere: SequelizeJSON
    $isFundraiser: Boolean!
    $isTeam: Boolean!
  ) {
    findCampaigns(id: $campaignId) {
      id
      fundraiserDefaultHeaderAvatarImage
      fundraisers(where: $fundraiserWhere) @include(if: $isFundraiser) {
        id
        avatarImage
      }
      teamDefaultHeaderAvatarImage
      teams(where: $teamWhere) @include(if: $isTeam) {
        id
        avatarImage
      }
    }
  }
`;

const CampaignPageFundraiserAvatar = ({ className }) => {
  const { campaignId, fundraiserId, teamId, pageType } = useCampaignPage();
  const { data } = useQuery(GET_CAMPAIGN, {
    variables: {
      campaignId,
      teamWhere: { id: teamId },
      fundraiserWhere: { id: fundraiserId },
      isFundraiser: pageType === 'fundraiser',
      isTeam: pageType === 'team',
    },
  });

  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const fundraiser = useMemo(() => campaign?.fundraisers?.[0], [campaign]);
  const team = useMemo(() => campaign?.teams?.[0], [campaign]);

  const avatar = useMemo(() => {
    if (pageType === 'fundraiser') {
      return (
        fundraiser?.avatarImage ||
        campaign?.fundraiserDefaultHeaderAvatarImage ||
        config('/defaultFundraiserAvatar')
      );
    }

    return (
      team?.avatarImage || campaign?.teamDefaultHeaderAvatarImage || config('/defaultTeamAvatar')
    );
  }, [pageType, campaign, fundraiser, team]);

  return (
    <div className={cx('rounded-full overflow-hidden border border-gray-300', className)}>
      <Image
        publicId={avatar}
        transformation="avatar"
        className="block w-full h-full object-center object-cover"
        alt=""
      />
    </div>
  );
};

CampaignPageFundraiserAvatar.propTypes = {
  className: PropTypes.string,
};

CampaignPageFundraiserAvatar.defaultProps = {
  className: '',
};

export default CampaignPageFundraiserAvatar;
