import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { faCalendarStar, faStopwatch, faPennant } from '@fortawesome/pro-regular-svg-icons';

import { formatNumber, formatDateRange } from 'lib/formatters';
import { useCampaignPage } from 'context/CampaignPage';
import Tiles from 'components/common/Tiles';
import Link from 'components/common/Link';
import CampaignPageFundraiserAvatar from './CampaignPageFundraiserAvatar';
import CampaignPageFundraiserStatLine from './CampaignPageFundraiserStatLine';
import CaptainHat from '../../svg/icon-captain-hat.svg';

const GET_CAMPAIGN = gql`
  query GetCampaignPageTeamInfo($campaignId: String!, $teamWhere: SequelizeJSON!) {
    findCampaigns(id: $campaignId) {
      id
      isPerformanceEnabled
      isMetricLabelLocked
      performanceStartDate
      performanceEndDate
      name
      teams(where: $teamWhere) {
        id
        members(where: { isTeamCaptain: true }) {
          id
          resolvedName
        }
        performanceSettings {
          metricLabel
          metricLabelPlural
        }
        stats {
          aggregatedPerformanceResult
        }
      }
    }
  }
`;

const CampaignPageTeamInfo = ({ className }) => {
  const { campaignId, teamId } = useCampaignPage();
  const { data } = useQuery(GET_CAMPAIGN, { variables: { campaignId, teamWhere: { id: teamId } } });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const team = useMemo(() => campaign?.teams[0], [campaign]);
  const captains = useMemo(() => team?.members, [team]);

  const aggregatedPerformance = formatNumber(
    team?.stats.aggregatedPerformanceResult ?? 0,
    '0,0.[00]'
  );

  const showAggregatedPerformance =
    campaign?.isPerformanceEnabled &&
    campaign?.isMetricLabelLocked &&
    team?.performanceSettings?.metricLabel &&
    aggregatedPerformance !== 0;

  const aggregatedPerformanceMetricLabel =
    aggregatedPerformance === 1
      ? team?.performanceSettings?.metricLabel
      : team?.performanceSettings?.metricLabelPlural;

  const aggregatedPerformanceLabel = `${aggregatedPerformance} total ${aggregatedPerformanceMetricLabel}`;

  const performanceDateRange = useMemo(() => {
    if (!campaign?.isPerformanceEnabled) return null;

    return formatDateRange(campaign?.performanceStartDate, campaign?.performanceEndDate);
  }, [campaign]);

  if (!campaign) return null;

  return (
    <div className={className}>
      <div className="hidden lg:flex items-center mb-6">
        <CampaignPageFundraiserAvatar className="-ml-4 w-16 h-16" />
        <h2 className="text-xl leading-normal font-medium ml-6">Team Profile</h2>
      </div>
      <Tiles spacing="sm" columns={1}>
        {showAggregatedPerformance && (
          <CampaignPageFundraiserStatLine icon={faStopwatch} label={aggregatedPerformanceLabel} />
        )}
        <CampaignPageFundraiserStatLine
          icon={faCalendarStar}
          label={
            <>
              Team participating in{' '}
              <Link
                href={`/c/${campaign.id}`}
                className="text-theme-secondary hover:text-theme-secondary-light transition-colors duration-200"
              >
                {campaign.name}
              </Link>
            </>
          }
        />
        {campaign.isPerformanceEnabled && (
          <CampaignPageFundraiserStatLine
            icon={faPennant}
            label={`Takes place ${performanceDateRange}`}
          />
        )}
        {captains.length > 0 && (
          <CampaignPageFundraiserStatLine
            icon={CaptainHat}
            label={
              <>
                Captained by{' '}
                {captains.map((captain, i) => {
                  let leadIn = ', ';
                  if (i === captains.length - 1) leadIn = ' and ';
                  if (i === 0) leadIn = '';

                  return (
                    <span key={captain.id}>
                      {leadIn}
                      <Link
                        href={`/f/${captain.id}`}
                        className="text-theme-secondary hover:text-theme-secondary-light transition-colors duration-200"
                      >
                        {captain.resolvedName}
                      </Link>
                    </span>
                  );
                })}
              </>
            }
          />
        )}
      </Tiles>
    </div>
  );
};

CampaignPageTeamInfo.propTypes = {
  className: PropTypes.string,
};

CampaignPageTeamInfo.defaultProps = {
  className: '',
};

export default CampaignPageTeamInfo;
