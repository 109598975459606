import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Image } from 'cloudinary-react';

import config from 'config';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormImageUploader from 'components/form/FormImageUploader';
import FormCurrencyInput from 'components/form/FormCurrencyInput';
import FormLabel from 'components/form/FormLabel';
import FormInput from 'components/form/FormInput';
import FormNote from 'components/form/FormNote';

const GET_CAMPAIGN = gql`
  query GetCampaign($id: String!) {
    findCampaigns(id: $id) {
      id
      teamDefaultHeaderAvatarImage
    }
  }
`;

const TeamAdminEditPageProfileForm = ({ campaignId }) => {
  const { data } = useQuery(GET_CAMPAIGN, { variables: { id: campaignId } });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);

  const { control, formState } = useFormContext();
  const { errors } = formState;

  if (!campaign) return null;

  return (
    <div className="mb-3">
      <FormFieldGroup>
        <div className="mb-1">
          <FormNode>
            <FormLabel className="hidden" htmlFor="avatarImage">
              Profile Photo
            </FormLabel>
            <Controller
              control={control}
              name="avatarImage"
              render={({ field }) => (
                <FormImageUploader
                  placeholder={
                    campaign.teamDefaultHeaderAvatarImage ?? config('/defaultTeamAvatar')
                  }
                  preview={({ image }) => (
                    <Image
                      publicId={image}
                      alt=""
                      transformation="avatar"
                      className="block w-32 rounded-full border-4 border-white shadow-lg"
                    />
                  )}
                  aspectRatio={1 / 1}
                  allowCrop
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="avatarImage"
              render={({ message }) => <FormNote status="error">{message}</FormNote>}
            />
          </FormNode>
        </div>
        <FormNode>
          <FormLabel htmlFor="name">Team Name</FormLabel>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <FormInput type="text" status={fieldState.error ? 'error' : 'default'} {...field} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>
        <FormNode>
          <FormLabel htmlFor="monetaryGoal">Fundraising Goal</FormLabel>
          <Controller
            control={control}
            name="monetaryGoal"
            render={({ field, fieldState }) => (
              <FormCurrencyInput status={fieldState.error ? 'error' : 'default'} {...field} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="monetaryGoal"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>
        <FormNode>
          <FormLabel htmlFor="slug">Fundraising Page Vanity URL</FormLabel>
          <div className="relative flex items-center">
            <div className="flex justify-center items-center px-5 h-10 rounded-lg bg-gray-200 border border-gray-400 text-gray-500 mr-2">
              {`${config('/hostname')}...@`}
            </div>
            <div className="grow">
              <Controller
                control={control}
                name="slug"
                render={({ field, fieldState }) => (
                  <FormInput
                    placeholder="team-name"
                    status={fieldState.error ? 'error' : 'default'}
                    maxLength={64}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <ErrorMessage
            errors={errors}
            name="slug"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>
      </FormFieldGroup>
    </div>
  );
};

TeamAdminEditPageProfileForm.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

TeamAdminEditPageProfileForm.defaultProps = {};

export default TeamAdminEditPageProfileForm;
