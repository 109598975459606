import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Image } from 'cloudinary-react';

import config from 'config';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormImageUploader from 'components/form/FormImageUploader';
import FormLabel from 'components/form/FormLabel';
import FormInput from 'components/form/FormInput';
import FormTextEditor from 'components/form/FormTextEditor';
import FormNote from 'components/form/FormNote';

const GET_CAMPAIGN = gql`
  query GetCampaign($id: String!) {
    findCampaigns(id: $id) {
      id
      teamDefaultHeaderBackgroundImage
      campaignHeaderBackgroundImage
    }
  }
`;

const TeamAdminEditPageOverviewForm = ({ campaignId }) => {
  const { data } = useQuery(GET_CAMPAIGN, { variables: { id: campaignId } });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);

  const { control, formState } = useFormContext();
  const { errors } = formState;

  if (!campaign) return null;

  return (
    <FormFieldGroup>
      <FormNode>
        <FormLabel className="hidden" htmlFor="headerImage">
          Cover Photo
        </FormLabel>
        <Controller
          control={control}
          name="headerImage"
          render={({ field }) => (
            <FormImageUploader
              aspectRatio={4 / 3}
              allowCrop
              placeholder={
                campaign.teamDefaultHeaderBackgroundImage ??
                campaign.campaignHeaderBackgroundImage ??
                config('/defaultTeamHeaderImage')
              }
              preview={({ image }) => (
                <Image
                  publicId={image}
                  alt=""
                  width={800}
                  aspectRatio="4:3"
                  crop="fill"
                  fetchFormat="auto"
                  gravity="auto:faces"
                  className="block w-full max-w-lg rounded-xl border-4 border-white shadow-lg"
                />
              )}
              editLabel
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="headerImage"
          render={({ message }) => <FormNote status="error">{message}</FormNote>}
        />
      </FormNode>
      <FormNode>
        <FormLabel htmlFor="storyTitle">Page Title</FormLabel>
        <Controller
          control={control}
          name="storyTitle"
          render={({ field, fieldState }) => (
            <FormInput
              type="text"
              maxLength={250}
              status={fieldState.error ? 'error' : 'default'}
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="storyTitle"
          render={({ message }) => <FormNote status="error">{message}</FormNote>}
        />
      </FormNode>
      <FormNode>
        <FormLabel htmlFor="story">Story</FormLabel>
        <Controller
          control={control}
          name="story"
          render={({ field, fieldState }) => (
            <FormTextEditor status={fieldState.error ? 'error' : 'default'} {...field} />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="story"
          render={({ message }) => <FormNote status="error">{message}</FormNote>}
        />
      </FormNode>
    </FormFieldGroup>
  );
};

TeamAdminEditPageOverviewForm.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

TeamAdminEditPageOverviewForm.defaultProps = {};

export default TeamAdminEditPageOverviewForm;
