import { useState, useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import Router from 'next/router';
import Sticky from 'react-sticky-el';
import { motion } from 'framer-motion';
import cx from 'classnames';

import { formatCurrency, formatNumber } from 'lib/formatters';
import { useCampaignPage } from 'context/CampaignPage';
import useBreakpoint from 'hooks/useBreakpoint';
import CampaignPageProgressDial from './CampaignPageProgressDial';
import CampaignPageActions from './CampaignPageActions';

const GET_CAMPAIGN = gql`
  query GetCampaignPageFundraiserProgress(
    $campaignId: String!
    $fundraiserWhere: SequelizeJSON
    $teamWhere: SequelizeJSON
    $isFundraiser: Boolean!
    $isTeam: Boolean!
  ) {
    findCampaigns(id: $campaignId) {
      id
      teamDefaultMonetaryGoal
      campaignDonateButtonLabel
      campaignJoinButtonLabel

      activeFeatures {
        giving
        join
      }

      fundraisers(where: $fundraiserWhere) @include(if: $isFundraiser) {
        id
        resolvedName
        monetaryGoal
        status
        performanceEstimate
        performanceSettings {
          metricLabelPlural
        }
        stats {
          estimatedAmountRaised
          actualAmountRaised
          aggregatedPerformanceResult
          pledgeValue
        }
      }

      teams(where: $teamWhere) @include(if: $isTeam) {
        id
        name
        monetaryGoal
        performanceSettings {
          metricLabelPlural
        }
        performanceEstimate
        stats {
          estimatedAmountRaised
          aggregatedPerformanceResult
          pledgeValue
        }
      }
    }
  }
`;

const CampaignPageFundraiserProgress = () => {
  const { campaignId, teamId, fundraiserId, pageType, setIsSharing } = useCampaignPage();
  const isTeam = pageType === 'team';

  const [floatActions, setFloatActions] = useState(false);
  const mobile = !useBreakpoint('lg');

  const { data } = useQuery(GET_CAMPAIGN, {
    variables: {
      campaignId,
      fundraiserWhere: { id: fundraiserId },
      teamWhere: { id: teamId },
      isFundraiser: pageType === 'fundraiser',
      isTeam: pageType === 'team',
    },
  });

  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const activeFeatures = campaign?.activeFeatures;
  const fundraiser = useMemo(
    () => campaign?.[isTeam ? 'teams' : 'fundraisers'][0],
    [isTeam, campaign]
  );

  const hasActions = !(isTeam && !activeFeatures?.join && !activeFeatures?.giving);
  const goal = fundraiser?.monetaryGoal ?? campaign?.teamDefaultMonetaryGoal;
  const metric = fundraiser?.performanceSettings?.metricLabelPlural;
  const raised = isTeam
    ? fundraiser?.stats.estimatedAmountRaised
    : fundraiser?.stats.actualAmountRaised;
  // eslint-disable-next-line no-unsafe-optional-chaining
  const pendingRaised = isTeam ? 0 : fundraiser?.stats.estimatedAmountRaised - raised;
  const isPerformanceBased = Boolean(metric);
  const isPerformanceClosed = ['confirmed', 'invoiced'].includes(fundraiser?.status);
  const hasPerformance = parseFloat(fundraiser?.stats.aggregatedPerformanceResult ?? 0) > 0;

  const showBasedOnText = useMemo(() => {
    if (!isPerformanceBased) {
      return false;
    }
    if (isTeam) {
      return false;
    }
    if (isPerformanceClosed && !hasPerformance) {
      return false;
    }
    return true;
  }, [isPerformanceBased, isTeam, isPerformanceClosed, hasPerformance]);

  const basedOnText = isPerformanceClosed
    ? `${formatNumber(fundraiser?.stats.aggregatedPerformanceResult, '0,0.[00]')} ${metric}`
    : `${formatNumber(fundraiser?.performanceEstimate, '0,0')} estimated ${metric}`;

  const displayName = useMemo(
    () => (isTeam ? fundraiser?.name : fundraiser?.resolvedName),
    [isTeam, fundraiser?.name, fundraiser?.resolvedName]
  );

  useEffect(() => {
    if (!mobile && floatActions) setFloatActions(false);
  }, [floatActions, setFloatActions, mobile]);

  if (!campaign) return null;

  return (
    <div className="text-center">
      <div className="relative z-0 w-56 -mt-28 md:w-72 md:-mt-36 lg:mt-0 lg:w-auto mx-auto bg-white rounded-full">
        <CampaignPageProgressDial
          goal={goal}
          raised={raised}
          pending={isPerformanceBased && !isPerformanceClosed ? pendingRaised : null}
        />
      </div>

      {showBasedOnText && (
        <>
          <p className="text-lg text-gray-700 leading-snug mt-6">
            Based on <strong className="font-medium">{basedOnText}</strong> each worth{' '}
            <strong className="font-medium">{formatCurrency(fundraiser.stats.pledgeValue)}</strong>
          </p>
          <div className="w-16 h-1 rounded-full bg-theme-secondary mx-auto my-4" />
        </>
      )}

      <h1 className="text-2xl leading-tight font-medium my-8">{displayName}</h1>

      {hasActions && (
        <Sticky
          stickyClassName="z-10"
          stickyStyle={{
            position: mobile ? 'fixed' : 'static',
            width: mobile ? '100%' : 'auto',
            top: 0,
            left: 0,
            right: 0,
          }}
          onFixedToggle={(isFixed) => setFloatActions(isFixed)}
        >
          <motion.div
            animate={{
              z: floatActions ? 100 : 0,
              y: floatActions ? 80 : 0,
            }}
            transition={{ duration: 0.4 }}
            className={cx('transition-colors duration-400', {
              'shadow-md bg-white py-3': floatActions,
              static: !floatActions,
            })}
          >
            <div className={cx({ container: floatActions })}>
              {isTeam ? (
                <CampaignPageActions
                  primaryLabel={activeFeatures.join ? campaign.campaignJoinButtonLabel : null}
                  onPrimaryClick={() => Router.push(`/t/${fundraiser.id}/join`)}
                  secondaryLabel={activeFeatures.giving ? campaign.campaignDonateButtonLabel : null}
                  onSecondaryClick={() => {
                    Router.push(`/t/${fundraiser.id}/contribute`);
                  }}
                />
              ) : (
                <CampaignPageActions
                  primaryLabel={activeFeatures.giving ? campaign.campaignDonateButtonLabel : null}
                  onPrimaryClick={() => {
                    Router.push(`/f/${fundraiser.id}/contribute`);
                  }}
                  secondaryLabel="Share"
                  onSecondaryClick={() => setIsSharing(true)}
                />
              )}
            </div>
          </motion.div>
        </Sticky>
      )}
    </div>
  );
};

export default CampaignPageFundraiserProgress;
