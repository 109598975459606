import { useEffect, useRef, ElementType } from 'react';
import cx from 'classnames';

import Link from './Link';

type RoundedSubnavItemProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    children: NonEmptyReactNode;
    active?: boolean;
    className?: string;
    color?: 'teal' | 'gray-800';
    bordered?: boolean;
  }
>;

const RoundedSubnavItem = <C extends ElementType = typeof Link>({
  as,
  children,
  color = 'teal',
  className = '',
  active = false,
  bordered = false,
  ...props
}: RoundedSubnavItemProps<C>) => {
  const Component = as || Link;
  const el = useRef(null);

  useEffect(() => {
    if (active) el.current?.scrollIntoView({ block: 'nearest', inline: 'center' });
  }, [active]);

  return (
    <Component
      ref={el}
      className={cx(
        'block font-medium rounded-full border-2 py-2 transition-all duration-200 whitespace-nowrap',
        {
          'border-transparent': !active && !bordered,
          'px-4': active || bordered,
          'bg-opacity-20': active,

          'text-teal-500': color === 'teal',
          'lg:hover:text-teal-300': !active && color === 'teal',
          'border-teal-300': !active && color === 'teal' && bordered,
          'border-teal-500 bg-teal-300': active && color === 'teal',

          'text-gray-800': color === 'gray-800',
          'lg:hover:text-gray-600': !active && color === 'gray-800',
          'border-gray-400': !active && color === 'gray-800' && bordered,
          'border-gray-800 bg-gray-600': active && color === 'gray-800',
        },
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

export default RoundedSubnavItem;
