import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import Router from 'next/router';

import config from 'config';
import { useCampaignPage } from 'context/CampaignPage';
import { parseNumber } from 'lib/formatters';
import Button from 'components/common/Button';
import CampaignLeaderboard from 'components/common/CampaignLeaderboard';
import Pill from 'components/common/Pill';

const INITIAL_LIMIT = 5;
const MORE_LIMIT = 10;

const GET_CAMPAIGN = gql`
  query GetCampaignPageTeamMembersLeaderboard($campaignId: String!, $teamWhere: SequelizeJSON!) {
    findCampaigns(id: $campaignId) {
      id
      isMetricLabelLocked
      fundraiserDefaultHeaderAvatarImage
      performanceTrackingLevel
      teams(where: $teamWhere) {
        id
        members {
          id
          type
          avatarImage
          resolvedName
          stats {
            aggregatedPerformanceResult
            estimatedAmountRaised
          }
        }
        performanceSettings {
          metricLabelPlural
        }
      }
    }
  }
`;

const CampaignPageTeamMembersLeaderboard = ({ className }) => {
  const [limit, setLimit] = useState(INITIAL_LIMIT);

  const { campaignId, teamId } = useCampaignPage();
  const { data } = useQuery(GET_CAMPAIGN, { variables: { campaignId, teamWhere: { id: teamId } } });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const team = useMemo(() => campaign?.teams[0], [campaign]);
  const members = useMemo(() => team?.members ?? [], [team]);

  const hasMore = members.length > limit;
  const metric = team?.performanceSettings?.metricLabelPlural;
  const hasPerformance =
    campaign?.performanceTrackingLevel === 'fundraiser' &&
    campaign?.isMetricLabelLocked &&
    Boolean(metric);

  if (!campaign) return null;

  return (
    <div className={className}>
      <h2 className="text-xl font-medium mb-4">
        Team Members <Pill className="ml-1">{members.length}</Pill>
      </h2>
      <CampaignLeaderboard
        type="individual"
        display={hasPerformance ? ['raised', 'performance'] : 'raised'}
        performanceLabel={metric}
        data={members.map((x) => ({
          id: x.id,
          image:
            x.avatarImage ??
            campaign?.fundraiserDefaultHeaderAvatarImage ??
            config('/defaultFundraiserAvatar'),
          name: x.resolvedName,
          raised: parseNumber(x.stats.estimatedAmountRaised) ?? 0,
          performance: parseNumber(x.stats.aggregatedPerformanceResult) ?? 0,
          isFundraiser: x.type === 'fundraiser',
        }))}
        onClick={(x) => (x.isFundraiser ? Router.push(`/f/${x.id}`) : {})}
        isClickable={(x) => x.isFundraiser}
        limit={limit}
        sortable
      />
      {hasMore && (
        <div className="mt-8 text-center">
          <Button
            as="button"
            type="button"
            onClick={() => setLimit(limit + MORE_LIMIT)}
            color="gray-300"
            className="font-medium rounded-full"
            outline
          >
            Show more
          </Button>
        </div>
      )}
    </div>
  );
};

CampaignPageTeamMembersLeaderboard.propTypes = {
  className: PropTypes.string,
};

CampaignPageTeamMembersLeaderboard.defaultProps = {
  className: '',
};

export default CampaignPageTeamMembersLeaderboard;
