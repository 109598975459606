import cx from 'classnames';

import Tiles from './Tiles';

type RoundedSubnavProps = PropsWithNonEmptyChildren<{
  className?: string;
  spacing?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}>;

const RoundedSubnav = ({ className = '', spacing = 'md', children }: RoundedSubnavProps) => (
  <div className={cx('overflow-x-auto hide-scroll text-left -mx-6', className)}>
    <Tiles spacing={spacing} className="mx-auto w-content px-6" wrap={false}>
      {children}
    </Tiles>
  </div>
);

export default RoundedSubnav;
